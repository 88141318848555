 
  .not-found-container {
    padding: 50px;
    text-align: center;
  }
  
  .not-found-title {
    font-size: 72px;
    margin-bottom: 20px;
  }
  
  .not-found-description {
    font-size: 24px;
    margin-bottom: 30px;
  }
  
  .not-found-link {
    font-size: 18px;
    color: #007bff;
    text-decoration: none;
  }
  
  .not-found-link:hover {
    text-decoration: underline;
  }
  