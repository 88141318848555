/* Provide sufficient contrast against white background */

@font-face {
    font-family: 'arial';
    src: url('components/assets/fonts/arial-font/arial.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
body {
    background-color: #fbfbfb;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

/*@font-face {
    font-family: 'BoutrosART-Medium';
    src: url('components/assets/fonts/arial-font/alfont_com_AlFont_com_BoutrosART-Medium-1.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

body {
    background-color: #fbfbfb;
    font-family: BoutrosART-Medium !important;
}*/
.fs-h-1
{
    font-size: 56px !important;
    line-height: 1.07143;
    font-weight: 600 !important;
    letter-spacing: -.005em;
}
.fs-h-2
{
    font-size: 45px !important;
    line-height: 1.07143;
    font-weight: 600 !important;
    letter-spacing: -.005em;
}
.fs-h-5
{
    font-size: 22px  !important;
    line-height: 1.07143;
    font-weight: 600 !important;
    letter-spacing: -.005em;
}
.custom-hr {
    border: 4px solid white; /* White and bold */
    opacity: 1; /* Fully visible */
  }
a {
    color: var(--bs-primary);
}

.btn:focus, .btn:active:focus, .btn-link.nav-link:focus, .form-control:focus, .form-check-input:focus {
    box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #258cfb;
}

code {
    color: #E01A76;
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--bs-primary);
    --bs-btn-border-color: var(--bs-primary);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--bs-primary);
    --bs-btn-hover-border-color: var(--bs-primary);
    --bs-btn-focus-shadow-rgb: var(--bs-primary);
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--bs-primary);
    --bs-btn-active-border-color: var(--bs-primary);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: var(--bs-primary);
    --bs-btn-disabled-border-color: var(--bs-primary);
}


.btn:hover {
    opacity: 0.5;
}

.bg-primary {
    background-color: var(--bs-primary) !important;
}

.page-link.active, .active > .page-link {
    background-color: var(--bs-primary);
    border-color: var(--bs-primary);
}

.page-link {
    color: var(--bs-primary);
}

    .page-link:hover {
        color: var(--bs-primary);
    }

.text-primary {
    color: var(--bs-primary) !important;
}

html {
    position: relative;
    min-height: 100%;
}

.main-container {
    margin-bottom: 100px;
}

.footer {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    margin-top: 20px;
}

@media only screen and (max-width: 767px) {
    /* Your mobile-specific styles go here */
    .logo {
        width: 140px !important;
    }

    .project-index-logo {
        width: 50px !important;
        height: 50px !important;
    }
    .project-layout-logo {
        background-image: url(components/assets/img/logo-icon.svg);
        width: 30px;
        height: 23px;
        background-repeat: no-repeat; /* Prevent the image from repeating */
        background-size: contain; /* Scale the image to fit within the container */
        background-position: center; /* Center the image */
    }
}

@media (min-width: 768px) {
    .project-layout-logo {
        background-image: url(components/assets/img/logo-w.svg);
        width: 200px;
        height: 36px;
        background-repeat: no-repeat; /* Prevent the image from repeating */
        background-size: contain; /* Scale the image to fit within the container */
        background-position: center; /* Center the image */
    }

}

.logo {
    width: 200px;
}

.project-index-logo {
    width: 100px;
    height: 100px;
}

:root {
    --bs-primary: #003C7F;
}

.fixed-preview {
    position: fixed;
    top: 20px; /* Adjust to place it slightly below the top */
    right: 20px; /* Adjust to place it near the right edge */
    width: 300px; /* Adjust the width as needed */
    z-index: 1000; /* Ensure it appears above other content */
}

.sticky-preview {
    position: sticky;
    top: 20px; /* Adjust to place it slightly below the top */
}

/* styles.css */
.draggable-list {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #ccc;
    border-radius: 4px;
    min-height: 100px; /* Adjust according to your layout */
    margin-bottom: 10px;
    padding: 10px 0;
}

.draggable-item {
    background-color: #fff;
    border-radius: 4px;
    padding: 8px 4px;
    margin: 8px;
    font-size: 13px;
    border-radius: 8px; /* Add border-radius if you want rounded corners */
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); /* Define the shadow effect */
}
.btn-draggable-item {
    text-align: center;
    align-content: center;
    align-self: center;
}
 